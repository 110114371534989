export default {
  colors: {
    layout: {
      background: '#2A2C35',
      primary: '#38B371',
      secondary: '#252833',
      tertiary: '#68708F',
      translucent: 'rgba(119, 128, 160, 0.18)',
      imageOverlay: 'rgba(56, 179, 113, 0.8)'
    },
    fonts: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.6)',
      accent: '#38B371'
    }
  },
  fonts: {
    body: {
      family: 'Rubik',
      weight: '400'
    },
    bold: {
      family: 'Rubik',
      weight: '700'
    },
    semiBold: {
      family: 'Rubik',
      weight: '600'
    },
    italic: {
      family: 'Rubik',
      weight: '700'
    }
  }
}
